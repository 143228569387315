//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-544:_9196,_6792,_3008,_1576,_7957,_7544,_2084,_2512;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-544')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-544', "_9196,_6792,_3008,_1576,_7957,_7544,_2084,_2512");
        }
      }catch (ex) {
        console.error(ex);
      }