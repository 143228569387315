import shopifyGenericDefaults from '../shopify-generic/config.js';

const AutoSyncKey = 'trucksrus';

const wheelsFields = shopifyGenericDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = shopifyGenericDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter((w) => !['FacetBarWheels', 'FacetBarTires'].includes(w.name)),
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
      minFacetCount: 2,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
      minFacetCount: 2,
    },
    {
      name: 'UpsizeDownsizeFacetPanel',
      type: 'FacetPanel',
      fields: ['wheel_tire_diameter'],
      showAllAlways: true,
      disableCollapse: true,
    },
    {
      name: 'WheelTireVehicleInfo',
      autoSyncVisualization: {
        apiKey: AutoSyncKey,
        config: {
          height: '120px',
        },
      },
    },
    {
      name: 'AutoSyncVisualizationDialog',
      apiKey: AutoSyncKey,
      template: 'fitmentSearch/autoSyncVisualizationDialog',
    },
  ],
};
